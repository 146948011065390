/* tslint:disable */
/* eslint-disable */
/**
 * Lovomi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BeziehungsSkalaResponse
 */
export interface BeziehungsSkalaResponse {
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof BeziehungsSkalaResponse
     */
    values?: Array<Array<number>> | null;
}

export function BeziehungsSkalaResponseFromJSON(json: any): BeziehungsSkalaResponse {
    return BeziehungsSkalaResponseFromJSONTyped(json, false);
}

export function BeziehungsSkalaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeziehungsSkalaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function BeziehungsSkalaResponseToJSON(value?: BeziehungsSkalaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': value.values,
    };
}


