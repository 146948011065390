import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, NavItem, NavLink, Navbar, NavbarToggler } from 'reactstrap';






export class SubNavbar extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom mb-3" light>
        <Container>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow-1">
              {Object.keys(this.props.routes).map((routeKey) => {
                const route = { ...this.props.routes[routeKey] };
                return <NavItem key={routeKey}><NavLink tag={Link} className="text-dark" to={route.path}>{route.name}</NavLink></NavItem>;
              })}
            </ul>

          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
