import React, { Component } from 'react';

export class Home extends Component {
  render () {
    return (
      <div>
        <h1>Wilkommen bei Lovimi!</h1>
        <p><a href='https://kurs.lovomi.de/momentum/' target='_blank' rel="noreferrer">Momentum Program</a> bei lovomi.de</p>
      </div>
    );
  }
}
