import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizeRoute from '../components/authorization/AuthorizeRoute';

export class RoutesRenderer extends Component {
  render() {
    const routes = this.props.routes;

    return (
      <Routes>
        {Object.keys(routes).map((routeKey) => {
          const route = {...routes[routeKey]};

          const routeProps = {...route.routeProps}
          const hasSubroutes = !!route.subRoutes;
          const hasElement = !!route.element;
          const catchAllDescendantRoutes = !!route.catchAllDescendantRoutes;
          const isHome = !!route.isHome;

          if(false) {
            console.log(routeKey)
            console.log("hasSubroutes: " + hasSubroutes)
            console.log("hasElement: " + hasElement)
            console.log("catchAllDescendantRoutes: " + catchAllDescendantRoutes)
            console.log("isHome: " + isHome)
            console.log(" ")
          }

          if(isHome && hasSubroutes) console.error("Route " + routeKey + " is marked as home and has subroutes. This is not implemented!")

          var routeElm = <></>

          // case: route has element and subroutes
          if(hasSubroutes && hasElement && !catchAllDescendantRoutes) {
            routeElm = <React.Fragment key={routeKey}>
              <Route path={route.path} {...routeProps} element={(!route.isAuthOnly ? route.element : <AuthorizeRoute element={route.element} />)} />
              <Route path={route.path + "/*"} {...routeProps} element={<RoutesRenderer routes={route.subRoutes} />} />
            </React.Fragment>

          } else if(hasElement) {
            if(isHome && !route.path) route.path = "/"; // in case the path is empty at home => default
            if(catchAllDescendantRoutes) route.path += "/*";

            routeElm = <Route key={routeKey} path={route.path} {...routeProps} element={(!route.isAuthOnly ? route.element : <AuthorizeRoute element={route.element} />)} />

          } else if(hasSubroutes) {
            // just render the subroutes

            routeElm = <Route key={routeKey} path={route.path + "/*"} {...routeProps} element={<RoutesRenderer routes={route.subRoutes} />} />
          }

          return routeElm;
        })}
      </Routes>
    );
  }
}
