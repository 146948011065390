import React, { Component } from 'react'
import ApiBackend from '../../api-backend/ApiBackend';
import ReactApexChart from 'react-apexcharts';

export class BeziehungsSkala extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newVal: 2.1,
      lowerEnd: 2,
      upperEnd: 3,
      data: []/*{
        [
          1673913600000,
          2
        ],
        [
          1688515200000,
          1.9
        ]
      }*/
    };

    this.textValues = [];
    this.textValues[1] = <>
      <h3>Level 1: Auf der Kippe - ihr steht ganz kurz vor einer Trennung</h3>
      <ul>
        <li>Denn wenn du in diesem Level bist, dann denkst du, aktiv darüber nach, dich zu trennen. Du planst deine Flucht. Oder vielleicht ist es ja dein Partner, der oder die genug hat und kurz davor steht sich zu trennen.</li>
        <li>Vielleichtht bist du auch nicht dabei, dich grade zu trennen… Aber innerlich denkst du schon sowas wie “Wenn die Kids 18 sind, bin ich hier raus…”.</li>
        <li>In Level 1 passiert es meistens, dass man sich stärker darauf konzentriert, wie du die Beziehung beenden kannst, als wie du sie retten und wieder schön machen kannst</li>
        <li>Oft steht man auch an diesem Punkt, wenn einer in der Beziehung eine Affäre hatte, die jetzt aufgeflogen ist</li>
      </ul>
    </>

    this.textValues[2] = <>
      <h3>Level 2: Mitbewohner - Im Grunde ist deine Beziehung so etwas wie eine schlechte WG</h3>
      <ul>
        <li>“Das ist doch keine Beziehung. Das ist wie ne WG hier. Und zwar ne echt miese.” Das beschreibt das Gefühl, was man in Level 2 hat ganz gut. Ihr wohnt zwar unter einem Dach, aber so richtig viel habt ihr euch nicht zu sagen.</li>
        <li>In dieser Phase gibt es oft Streit wegen der Aufgabenverteilung. Meistens weil die Frau das Gefühl hat, für alles zu Hause verantwortlich zu sein, während der Mann zuhause relativ passiv bleibt. Häufig fühlt sich die Frau mit allem alleine gelassen, während sich der Mann nicht respektiert und kritisert fühlt. </li>
        <li>Dabei eskaliert auch häufig ein Streit wegen irgendwelcher Kleinigkeiten. Wobei das nicht so sein muss. </li>
        <li>Manche Paare streiten sich in dieser Phase kaum noch. Aber nicht, weil es so harmonisch sind, sondern weil die Partner schon keine Kraft mehr haben.</li>
        <li>Es herrscht eine Distanz zwischen einander, die immer größer wird und die ihr nur noch in seltenen Momenten überwinden könnt. </li>
        <li>Sex passiert - wenn überhaupt - nur noch sehr selten. </li>
        <li>Und vielleicht seit ihr auch schon in Paartherapie. Oder zumindest hast du da schon drüber nachgedacht. </li>
      </ul>
    </>

    this.textValues[3] = <>
      <h3>Level 3: Freunde - Ihr seid ein gutes Team und der Alltag funktioniert auch ganz gut, aber es gibt nur sehr wenig Liebe zwischen euch</h3>
      <ul>
        <li>Ihr seid ein gutes Team. Wahrscheinlich tolle Eltern und der Alltag ist eingespielt, ohne dass es größere Probleme gibt. Aber ihr seid schon so lange zusammen, dass ihr gefühlt eher Freunde als ein Liebespaar seid.</li>
        <li>Vielleichtht ist es - wenn du dich mal wirklich hinterfragst - eher eine Beziehung ist aus Bequemlichkeit, als weil ihr unbedingt zusammen sein wollt. Ihr teilt euch das Bankkonto, kümmert euch beide um die Kinder… Alles viel bequemer, als wenn du alleine wärst.</li>
        <li>Aber du fragst dich schon manchmal, ob das alles gewesen ist.</li>
        <li>Wenn du dich fragst, ob du dich nochmal für diesen Partner entscheiden würdest, bist du dir nicht mehr wirklich sicher. Zumindest hast du Zweifel, ob es mit jemand anders nicht vielleicht besser funktioniert hätte...</li>
        <li>Irgendwie hättest du gerne wieder eine aufregendere Beziehung und du glaubst auch, dass das mit deinem aktuellen Partner funktionieren könnte, aber du weißt nicht wie. Und selbst wenn du Ideen hast, fehlt euch Abends einfach zu oft die Energie, etwas zusammen zu machen.</li>
        <li>Erfüllung findet ihr eher durch andere Dinge als durch eure Partnerschaft. Durch die Kinder… Durch die Arbeit, Freunde oder Hobbys.</li>
        <li>Ihr habt zwar noch Sex, aber irgendwie ist auch da die Luft raus und es ist ziemlich vorhersagbar geworden</li>
      </ul>
    </>

    this.textValues[4] = <>
      <h3>Level 4: Ihr liebt euch, aber es gibt praktisch keine Leidenschaft in eurer Beziehung</h3>
      <ul>
        <li>Eure Beziehung fühlt sich toll an… Ihr liebt euch wirklich. Aber das Verlangen fehlt. Ihr seid euch so vertraut und kennt euch schon so lange und du weißt, dass du mit diesem Mann oder dieser Frau alt werden möchtest. </li>
        <li>Aber euch fehlt einfach die Leidenschaft. Das spielerische, neckische, das eure Beziehung grade in der Anfangszeit noch hatte. </li>
        <li>Ihr seid ein tolles Team, tolle Eltern und eure Freunde würden auch sagen ein Bilderbuch Paar. Aber damit du wirklich glücklich sein kannst, fehlt einfach die Leidenschaft, die du gerne wieder wecken würdest. Wenn du wüsstest, wie.</li>
      </ul>
    </>

    this.textValues[5] = <>
      <h3>Level 5: Ist sozusagen Wolke 7. Ihr spürt beide eine tiefe Liebe und die Leidenschaft ist wieder da.</h3>
    </>



  }

  componentDidMount() {
    this.loadHistory();
  }

  async loadHistory() {
    var response = await ApiBackend.Fundament.getBeziehungsSkala();
    this.setState({data: response.values})
  }

  onSliderChange(e) {
    const newVal = e.target.value;
    const lowerEnd = Math.min(4, Math.floor(newVal))
    const upperEnd = Math.max(Math.ceil(newVal), lowerEnd + 1)

    if(newVal >= this.state.lowerEnd && newVal <= this.state.upperEnd) {// is same range
      this.setState({newVal: newVal}) // only set the newVal
    } else {
      this.setState({
        newVal: newVal,
        upperEnd: upperEnd,
        lowerEnd: lowerEnd
    
      })
    }
  }

  async onSave() {
    var response = await ApiBackend.Fundament.setBeziehungsSkalaForToday({body: this.state.newVal});
    this.setState({data: response.values})
  }



  render() {
    const lowerEnd = this.state.lowerEnd
    const upperEnd = this.state.upperEnd

    return (
      <>
        <h1>Die 5 Level der Beziehungs-Skala</h1>
        <h2>Verlauf</h2>
        <HistoryChart data={this.state.data}/>
        <br />
        <h2>Wo steht ihr auf der Beziehungs-Skala?</h2>
        <div className="container">
          <div className="row">
            <div className="col-2">
              <input type="number" step="0.1" min="1" max="5" id="typeNumber" className="form-control" onChange={(e) => this.onSliderChange(e)} value={this.state.newVal} />
            </div>
            <div className="col-10" style={{margin:"auto", lineHeight:1}}>
              <input type="range" step="0.1" className="form-control-range" min="1" max="5" onChange={(e) => this.onSliderChange(e)} value={this.state.newVal} style={{width:"100%"}} />
            </div>
            <div className="col-5">
              <input type="button" className="btn btn-success mt-3" value="Für heute speichern" onClick={() => this.onSave()}/>
            </div>
          </div>
          <br/>
          <hr/>
          <input type="range" step="0.1" className="form-control-range" min={lowerEnd} max={upperEnd} onChange={(e) => this.onSliderChange(e)} value={this.state.newVal} style={{width:"100%"}} />
          <br/>
          <div className="row">
            <div className="col-6">{this.textValues[lowerEnd]}</div>
            <div className="col-6">{this.textValues[upperEnd]}</div>
          </div>
        </div>
      </>
    )
  }
}

export default BeziehungsSkala


class HistoryChart extends React.Component {

  render() {
    const options = {
      chart: {
        height: 350,
        type: "line",
        id: "areachart-2",
      },
      dataLabels: { enabled: false, },
      stroke: { curve: "straight", },
      grid: { padding: { right: 30, left: 20, }, },
      xaxis: { type: "datetime", },
      yaxis: {min: 1, max: 5, tickAmount: 4}
    }
    const series = [
      {
        name: "Beziehungs-Skala",
        data: this.props.data,
      },
    ]

    return (
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
    );
  }
}
