import React from 'react'

import { Home } from '../components/Home';

// Authentication
import Login from '../components/authentication/Login';
import Logout from '../components/authentication/Logout';
import Register from '../components/authentication/Register';

import Imprint from '../components/Imprint';
import DataProtection from '../components/DataProtection';
import Fundament from '../components/lovomi/fundament/Fundament';
import Modul1 from '../components/lovomi/modul1/Modul1';
import BeziehungsSkala from '../components/lovomi/fundament/BeziehungsSkala';

/**
 * RouteName : { element: <element> }
 */

const AppRoutes = {
  Home: { element: <Home />, isHome: true},

  Fundament: { 
    element: <Fundament />, isAuthOnly: true, path: 'Fundament', catchAllDescendantRoutes: true,
    subRoutes : {
      BeziehungsSkala: { element: <BeziehungsSkala />, name:"Beziehungs-Skala", path: 'beziehungs-skala' }, 
      Test2: { element: <Home/>, name:"Test 2", path: 'Test2' }, 
      Test3: { element: <Home/>, name:"Test 3", path: 'Test3' }, 
    }
  },
  Modul1: { element: <Modul1 />, path: 'Modul1', catchAllDescendantRoutes: true,
    subRoutes : {
      Test1: { element: <Home/>, name:"Test 1", path: 'Test1' }, 
      Test2: { element: <Home/>, name:"Test 2", path: 'Test2' }, 
      Test3: { element: <Home/>, name:"Test 3", path: 'Test3' }, 
    }
  },

  
  Authentication : {
    path: 'Authentication',
    subRoutes : {
      Login: { element: <Login/>, path: 'Login' }, 
      Register: { element: <Register/>, path: 'Register' }, 
      LogOut: { element: <Logout/>, path: 'Logout' }, 
    }
  },

  Account : {
    path: 'Account',
    subRoutes : {
      Profile: { element: <h1>Profile</h1>, path: 'Profile' }, 
      ForgotPassword: { element: <h1>ForgotPassword</h1>, path: 'ForgotPassword' }, 
    }
  },

  Imprint: { element: <Imprint />, path: 'Impressum' },
  DataProtection: { element: <DataProtection />, path: 'Datenschutz' },
};

export function createFullPath(key, subkey) {
  return "/" + AppRoutes[key].path + "/" + AppRoutes[key].subRoutes[subkey].path;
} 

export default AppRoutes;
