import React, { Component } from 'react'
import AppRoutes from '../../../routing/AppRoutes'
import { SubNavbar } from '../SubNavbar'
import { RoutesRenderer } from '../../../routing/RoutesRenderer'

export class Fundament extends Component {


  render() {
    var routesToRender = AppRoutes.Fundament.subRoutes;
    routesToRender.Main = { element: <Main />, isHome: true};

    return (  
      <>
        <SubNavbar routes={AppRoutes.Fundament.subRoutes} />
        <RoutesRenderer routes={routesToRender} />
      </>

    )
  }
}

export default Fundament



export class Main extends Component {
  render() {
    return (
      <>
        <h1>Fundament</h1>
        <p><a href='https://kurs.lovomi.de/fundament-momentum/' target='_blank' rel="noreferrer">Momentum Program</a> bei lovomi.de</p>
      </>
    )
  }
}
