import React, { Component } from 'react';
import ApiBackend from './api-backend/ApiBackend';
import { Link } from 'react-router-dom'
import AppRoutes from '../routing/AppRoutes';
import preval from 'preval.macro'

export class VersionInfo extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      backendVersion: "loading..."
    };
  }

  componentDidMount() {
    this.populateData();
  }

  async populateData() {
    var api = ApiBackend.Version; 
    var backendVersion = await api.index();
   
    console.log(backendVersion);

    this.setState({backendVersion: backendVersion});
  }

  render() {
    const versionString = (process.env.REACT_APP_VERSION === "DEBUG" || 
                           process.env.REACT_APP_VERSION === this.state.backendVersion) ? 
      process.env.REACT_APP_VERSION : 
      "Frontend: "+ process.env.REACT_APP_VERSION + " | Backend: " + this.state.backendVersion
    const year = preval`module.exports = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date());`

    return (
      <>
        <div id="push" style={{height:"40px"}} />
        <div style={{height:"40px", color: 'darkgray', zindex: -1, backgroundColor: "rgb(255,255,255,0.9"}} className="navbar fixed-bottom container">
          <div>© 2021-{year} Simon Schumacher - <Link to={AppRoutes.Imprint.path}>Impressum</Link> - <Link to={AppRoutes.DataProtection.path}>Datenschutz</Link></div>
          <div className="float-right hide-sm">{versionString}</div>
        </div>
      </>
    );
  }
}
